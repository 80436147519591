angular.module('meanApp')
.controller('flex-summary-controller', function ($scope, DataM, Auth, $rootScope) {
    'use strict';

    // Private Methods
    $scope.compute = function(data) {
        const x = {
            totalLivingExpense: data.filter(i => i.flexTitle !== "Rental Expense").reduce((total, current) => (
                {totalYearlyAmount : total.totalYearlyAmount + current.totalYearlyAmount}
            )).totalYearlyAmount,
            rentalExpense : data.map((i) => {
                const x =  i.sub.find((ii) => (ii.title === "Rent") )
                if(x) return x.yearlyAmount
            }).find(Boolean)
        }
        let { totalLivingExpense, rentalExpense} = x;
        // console.log({x})
        totalLivingExpense = totalLivingExpense ? totalLivingExpense : 0
        rentalExpense = rentalExpense ? rentalExpense : 0
        return {
            totalLivingExpense,
            rentalExpense,
            totalExpense : totalLivingExpense + rentalExpense
        }
    }


    $scope.data = [{}];
    $scope.computed = {
        totalLivingExpense : 0,
        rentalExpense : 0,
        totalExpense : 0
    }
    
    // fetch data from api

    $scope.fetchFlexSummaryData = function() {
        DataM.getFlexSummary().then(function (res) {
            $scope.data = res.data.filter(i => i.flexTitle !== "Rental Expense");
            $scope.computed = $scope.compute(res.data)
        })
    }
    
    $rootScope.$on('OnExpensesShowFlexSummary', function(event, data) {
        $scope.fetchFlexSummaryData();
    })

    // update scope
})