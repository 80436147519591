angular
  .module("meanApp")
  .controller("online-simulator-controller", function($scope, $stateParams) {
    "use strict";

    const reactContainerNode = document.getElementById("react-wrapper");

    ReactDOM.render(
      React.createElement(OnlineSimulator.default, {
        clientUID: $stateParams.clientId
      }),
      reactContainerNode
    );

    $scope.$on("$stateChangeStart", () => {
      ReactDOM.unmountComponentAtNode(reactContainerNode);
    });
  });
