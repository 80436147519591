'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('terms', {
        url: '/terms',
        controller: 'terms-controller',
        templateProvider:  function ($stateParams,$location,appHostnames){
          let hostname = $location.$$host;
          
          if(appHostnames.tpcHosts.indexOf(hostname) >-1){
             return  '<div ng-include="\'app/terms/tpc-terms.html\'"></div>';
          } else {
             return  '<div ng-include="\'app/terms/terms.html\'"></div>';
          }
          
        },
        authenticate: ['user']
      });
  });
