export const computeEstTaxPaid2019 = function (totalTaxableIncome) {
  let incomeTaxComputation = 0;
  // Info from reference data
  const incomeTaxRef = [
    { min: 0, max: 18200, excessRate: 0, tax: 0 },
    { min: 18201, max: 45000, excessRate: 19 / 100, tax: 0 },
    { min: 45001, max: 120000, excessRate: 32.5 / 100, tax: 5092 },
    { min: 120001, max: 180000, excessRate: 37 / 100, tax: 29467 },
    { min: 180001, excessRate: 45 / 100, tax: 51667 },
  ];

  /** 2019 / 2020 Low Middle Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation = 0;
  // Info from reference data
  const lowIncomeTaxRef = [
    { max: 37000, excessRate: 0, tax: 255 },
    { min: 37001, max: 48000, excessRate: 7.5 / 100, tax: 255 },
    { min: 48001, max: 90000, excessRate: 0, tax: 1080 },
    { min: 90001, max: 126000, excessRate: -3 / 100, tax: 1080 },
    { min: 126001, excessRate: 0, tax: 0 },
  ];
  /** 2019 / 2020 Low Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation2 = 0;
  const lowIncomeTaxRef2 = [
    { min: 0, max: 37500, excessRate: 0, tax: 700, excessRate: -1 },
    { min: 37501, max: 45000, excessRate: -5 / 100, tax: 700 },
    { min: 45001, max: 66667, excessRate: -1.5 / 100, tax: 325 },
    { min: 66668, excessRate: 0, tax: 0 },
  ];

  /** 2019 / 2020 Medicare Levy Scales and Thresholds */
  let medicalLevyComputation = 0;
  // Info from reference data
  const medicalLevyRef = [
    { min: 0, max: 22801, excessRate: 0, tax: 0 },
    { min: 22802, max: 28501, excessRate: 10 / 100, tax: 0 },
    { min: 28502, excessRate: 2 / 100, tax: 570 },
  ];

  /** INCOME TAX */

  if (totalTaxableIncome > incomeTaxRef[4].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[4].min) * incomeTaxRef[4].excessRate +
      incomeTaxRef[4].tax;
  } else if (totalTaxableIncome > incomeTaxRef[3].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[3].min) * incomeTaxRef[3].excessRate +
      incomeTaxRef[3].tax;
  } else if (totalTaxableIncome > incomeTaxRef[2].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[2].min) * incomeTaxRef[2].excessRate +
      incomeTaxRef[2].tax;
  } else if (totalTaxableIncome > incomeTaxRef[1].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[1].min) * incomeTaxRef[1].excessRate +
      incomeTaxRef[1].tax;
  } else if (totalTaxableIncome > incomeTaxRef[0].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  } else {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  }

  /** LOW INCOME */
  if (totalTaxableIncome > lowIncomeTaxRef[4].max) {
    lowIncomeTaxComputation = 0;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[3].min &&
    totalTaxableIncome < lowIncomeTaxRef[3].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef[3].min) *
        lowIncomeTaxRef[3].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[2].min &&
    totalTaxableIncome < lowIncomeTaxRef[2].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef[2].min) *
        lowIncomeTaxRef[2].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[1].min &&
    totalTaxableIncome < lowIncomeTaxRef[1].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef[1].min) *
        lowIncomeTaxRef[1].excessRate;
  } else if (
    totalTaxableIncome < lowIncomeTaxRef[0].max &&
    totalTaxableIncome > 0
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[0].tax +
      (totalTaxableIncome - lowIncomeTaxRef[0].max) *
        lowIncomeTaxRef[0].excessRate;
  }

  /** LOW INCOME 2 */
  if (totalTaxableIncome > lowIncomeTaxRef2[3].max) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].max) *
        lowIncomeTaxRef2[3].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[2].min &&
    totalTaxableIncome < lowIncomeTaxRef2[2].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].min) *
        lowIncomeTaxRef2[2].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[1].min &&
    totalTaxableIncome < lowIncomeTaxRef2[1].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[1].min) *
        lowIncomeTaxRef2[1].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[0].min &&
    totalTaxableIncome < lowIncomeTaxRef2[0].max
  ) {
    lowIncomeTaxComputation2 = lowIncomeTaxRef2[0].tax;
  }

  const allLowIncomeTax = -lowIncomeTaxComputation - lowIncomeTaxComputation2;

  /** MEDICAL LEVY */
  if (totalTaxableIncome > medicalLevyRef[1].max) {
    medicalLevyComputation =
      medicalLevyRef[2].tax +
      (totalTaxableIncome - medicalLevyRef[1].max) *
        medicalLevyRef[2].excessRate;
  } else if (
    totalTaxableIncome > medicalLevyRef[0].max &&
    totalTaxableIncome < medicalLevyRef[1].max
  ) {
    medicalLevyComputation =
      medicalLevyRef[0].tax +
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[1].excessRate;
  } else {
    medicalLevyComputation =
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax;
  }

  let estimatedTaxPaid =
    incomeTaxComputation + allLowIncomeTax + medicalLevyComputation;
  if (estimatedTaxPaid < 0) estimatedTaxPaid = 0;
  estimatedTaxPaid = Math.round(estimatedTaxPaid);
  return estimatedTaxPaid;
};

export const computeEstTaxPaid2017 = function (totalTaxableIncome) {
  /** 2018 / 2019 Income Tax Scales and Thresholds */
  let incomeTaxComputation = 0;
  // Info from reference data
  const incomeTaxRef = [
    { min: 0, max: 18200, excessRate: 0, tax: 0 },
    { min: 18201, max: 37000, excessRate: 19 / 100, tax: 0 },
    { min: 37001, max: 90000, excessRate: 32.5 / 100, tax: 3572 },
    { min: 90001, max: 180000, excessRate: 37 / 100, tax: 20797 },
    { min: 180001, excessRate: 47 / 100, tax: 54097 },
  ];

  /** 2018 / 2019 Low Middle Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation = 0;
  // Info from reference data
  const lowIncomeTaxRef = [
    { max: 37000, excessRate: 0, tax: 200 },
    { min: 37001, max: 48000, excessRate: -3 / 100, tax: 200 },
    { min: 48001, max: 90000, excessRate: 0, tax: 530 },
    { min: 90001, max: 125333, excessRate: -1.5 / 100, tax: 530 },
    { min: 125334, excessRate: 0, tax: 0 },
  ];
  /** 2018 / 2019 Low Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation2 = 0;
  const lowIncomeTaxRef2 = [
    { min: 0, max: 37000, excessRate: 0, tax: 445 },
    { min: 37001, max: 66667, excessRate: 1.5 / 100, tax: 445 },
    { min: 66668, excessRate: 0, tax: 0 },
  ];

  /** 2016 / 2017 Medicare Levy Scales and Thresholds */
  let medicalLevyComputation = 0;
  // Info from reference data
  const medicalLevyRef = [
    { min: 0, max: 21335, excessRate: 0, tax: 0 },
    { min: 21336, max: 26669, excessRate: 10 / 100, tax: 0 },
    { min: 26670, excessRate: 2 / 100, tax: 533 },
  ];
  /** INCOME TAX */
  if (taxableIncome > incomeTaxRef[4].min) {
    incomeTaxComputation =
      (taxableIncome - incomeTaxRef[4].min) * incomeTaxRef[4].excessRate +
      incomeTaxRef[4].tax;
  } else if (taxableIncome > incomeTaxRef[3].min) {
    incomeTaxComputation =
      (taxableIncome - incomeTaxRef[3].min) * incomeTaxRef[3].excessRate +
      incomeTaxRef[3].tax;
  } else if (taxableIncome > incomeTaxRef[2].min) {
    incomeTaxComputation =
      (taxableIncome - incomeTaxRef[2].min) * incomeTaxRef[2].excessRate +
      incomeTaxRef[2].tax;
  } else if (taxableIncome > incomeTaxRef[1].min) {
    incomeTaxComputation =
      (taxableIncome - incomeTaxRef[1].min) * incomeTaxRef[1].excessRate +
      incomeTaxRef[1].tax;
  } else if (taxableIncome > incomeTaxRef[0].min) {
    incomeTaxComputation =
      (taxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  } else {
    incomeTaxComputation =
      (taxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  }

  /** LOW INCOME */
  if (taxableIncome > lowIncomeTaxRef[3].min) {
    lowIncomeTaxComputation =
      (taxableIncome - lowIncomeTaxRef[3].min) * lowIncomeTaxRef[3].excessRate +
      lowIncomeTaxRef[3].tax;
  } else if (taxableIncome > lowIncomeTaxRef[2].min) {
    lowIncomeTaxComputation =
      (taxableIncome - lowIncomeTaxRef[1].max) * lowIncomeTaxRef[2].excessRate +
      lowIncomeTaxRef[2].tax;
  } else if (taxableIncome > lowIncomeTaxRef[1].min) {
    lowIncomeTaxComputation =
      (taxableIncome - lowIncomeTaxRef[0].max) * lowIncomeTaxRef[1].excessRate -
      lowIncomeTaxRef[1].tax;
  } else if (taxableIncome == lowIncomeTaxRef[0].max) {
    lowIncomeTaxComputation = -lowIncomeTaxRef[0].tax;
  }
  /** LOW INCOME 2 */
  if (taxableIncome > lowIncomeTaxRef2[2].min) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[2].tax -
      (taxableIncome - lowIncomeTaxRef2[1].max) *
        lowIncomeTaxRef2[2].excessRate;
  } else if (taxableIncome > lowIncomeTaxRef2[1].min) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[1].tax -
      (taxableIncome - lowIncomeTaxRef2[0].max) *
        lowIncomeTaxRef2[1].excessRate;
  } else if (taxableIncome <= lowIncomeTaxRef2[0].max) {
    lowIncomeTaxComputation2 = lowIncomeTaxRef2[0].tax;
  }
  lowIncomeTaxComputation = lowIncomeTaxComputation - lowIncomeTaxComputation2;
  /** MEDICAL LEVY */
  if (taxableIncome > medicalLevyRef[1].max) {
    medicalLevyComputation =
      (taxableIncome - medicalLevyRef[1].max) * medicalLevyRef[2].excessRate +
      (medicalLevyRef[1].max - medicalLevyRef[1].min) *
        medicalLevyRef[1].excessRate +
      (medicalLevyRef[0].max - medicalLevyRef[0].min) *
        medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax;
  } else if (medicalLevyComputation > medicalLevyRef[0].max) {
    medicalLevyComputation =
      (taxableIncome - medicalLevyRef[0].max) * medicalLevyRef[1].excessRate +
      (medicalLevyRef[0].max - medicalLevyRef[0].min) *
        medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax;
  } else {
    medicalLevyComputation =
      (taxableIncome - medicalLevyRef[0].min) * medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax;
  }

  let estimatedTaxPaid =
    Math.round(incomeTaxComputation - lowIncomeTaxComputation) +
    Math.round(medicalLevyComputation);

  if (estimatedTaxPaid < 0) {
    estimatedTaxPaid = 0;
  }
  estimatedTaxPaid = estimatedTaxPaid;

  return estimatedTaxPaid;
};

export const computeEstTaxPaid2021 = function (totalTaxableIncome) {
  let incomeTaxComputation = 0;
  // Info from reference data
  const incomeTaxRef = [
    { min: 0, max: 18200, excessRate: 0, tax: 0 },
    { min: 18201, max: 45000, excessRate: 19 / 100, tax: 0 },
    { min: 45001, max: 120000, excessRate: 32.5 / 100, tax: 5092 },
    { min: 120001, max: 180000, excessRate: 37 / 100, tax: 29467 },
    { min: 180001, excessRate: 45 / 100, tax: 51667 },
  ];

  /** 2020 / 2021 Low Middle Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation = 0;
  // Info from reference data
  const lowIncomeTaxRef = [
    { max: 37000, excessRate: 0, tax: 255 },
    { min: 37001, max: 48000, excessRate: 7.5 / 100, tax: 255 },
    { min: 48001, max: 90000, excessRate: 0, tax: 1080 },
    { min: 90001, max: 126000, excessRate: -3 / 100, tax: 1080 },
    { min: 126001, excessRate: 0, tax: 0 },
  ];
  /** 2020 / 2021 Low Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation2 = 0;
  const lowIncomeTaxRef2 = [
    { min: 0, max: 37500, excessRate: 0, tax: 700, excessRate: -1 },
    { min: 37501, max: 45000, excessRate: -5 / 100, tax: 700 },
    { min: 45001, max: 66667, excessRate: -1.5 / 100, tax: 325 },
    { min: 66668, excessRate: 0, tax: 0 },
  ];

  /** 2021 / 2022 Medicare Levy Scales and Thresholds */
  let medicalLevyComputation = 0;
  // Info from reference data
  const medicalLevyRef = [
    { min: 0, max: 23807, excessRate: 0, tax: 0 },
    { min: 23808, max: 29758, excessRate: 10 / 100, tax: 0 },
    { min: 29759, excessRate: 2 / 100, tax: 595 },
  ];

  /** INCOME TAX */

  if (totalTaxableIncome > incomeTaxRef[4].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[4].min) * incomeTaxRef[4].excessRate +
      incomeTaxRef[4].tax;
  } else if (totalTaxableIncome > incomeTaxRef[3].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[3].min) * incomeTaxRef[3].excessRate +
      incomeTaxRef[3].tax;
  } else if (totalTaxableIncome > incomeTaxRef[2].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[2].min) * incomeTaxRef[2].excessRate +
      incomeTaxRef[2].tax;
  } else if (totalTaxableIncome > incomeTaxRef[1].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[1].min) * incomeTaxRef[1].excessRate +
      incomeTaxRef[1].tax;
  } else if (totalTaxableIncome > incomeTaxRef[0].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  } else {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  }

  /** LOW INCOME */
  if (totalTaxableIncome > lowIncomeTaxRef[4].max) {
    lowIncomeTaxComputation = 0;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[3].min &&
    totalTaxableIncome < lowIncomeTaxRef[3].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef[3].min) *
        lowIncomeTaxRef[3].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[2].min &&
    totalTaxableIncome < lowIncomeTaxRef[2].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef[2].min) *
        lowIncomeTaxRef[2].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[1].min &&
    totalTaxableIncome < lowIncomeTaxRef[1].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef[1].min) *
        lowIncomeTaxRef[1].excessRate;
  } else if (
    totalTaxableIncome < lowIncomeTaxRef[0].max &&
    totalTaxableIncome > 0
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[0].tax +
      (totalTaxableIncome - lowIncomeTaxRef[0].max) *
        lowIncomeTaxRef[0].excessRate;
  }

  /** LOW INCOME 2 */
  if (totalTaxableIncome > lowIncomeTaxRef2[3].max) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].max) *
        lowIncomeTaxRef2[3].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[2].min &&
    totalTaxableIncome < lowIncomeTaxRef2[2].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].min) *
        lowIncomeTaxRef2[2].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[1].min &&
    totalTaxableIncome < lowIncomeTaxRef2[1].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[1].min) *
        lowIncomeTaxRef2[1].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[0].min &&
    totalTaxableIncome < lowIncomeTaxRef2[0].max
  ) {
    lowIncomeTaxComputation2 = lowIncomeTaxRef2[0].tax;
  }

  const allLowIncomeTax = -lowIncomeTaxComputation - lowIncomeTaxComputation2;

  /** MEDICAL LEVY */
  if (totalTaxableIncome > medicalLevyRef[1].max) {
    medicalLevyComputation =
      medicalLevyRef[2].tax +
      (totalTaxableIncome - medicalLevyRef[1].max) *
        medicalLevyRef[2].excessRate;
  } else if (
    totalTaxableIncome > medicalLevyRef[0].max &&
    totalTaxableIncome < medicalLevyRef[1].max
  ) {
    medicalLevyComputation =
      medicalLevyRef[0].tax +
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[1].excessRate;
  } else {
    medicalLevyComputation =
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax;
  }

  let estimatedTaxPaid =
    incomeTaxComputation + allLowIncomeTax + medicalLevyComputation;
  if (estimatedTaxPaid < 0) estimatedTaxPaid = 0;
  estimatedTaxPaid = Math.round(estimatedTaxPaid);
  return estimatedTaxPaid;
};

export const computeEstTaxPaid = function (totalTaxableIncome) {
  let incomeTaxComputation = 0;
  // Info from reference data
  const incomeTaxRef = [
    { min: 0, max: 18200, excessRate: 0, tax: 0 },
    { min: 18201, max: 45000, excessRate: 19 / 100, tax: 0 },
    { min: 45001, max: 120000, excessRate: 32.5 / 100, tax: 5092 },
    { min: 120001, max: 180000, excessRate: 37 / 100, tax: 29467 },
    { min: 180001, excessRate: 45 / 100, tax: 51667 },
  ];

  /** 2020 / 2021 Low Middle Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation = 0;
  // Info from reference data
  const lowIncomeTaxRef = [
    { max: 37000, excessRate: 0, tax: 255 },
    { min: 37001, max: 48000, excessRate: 7.5 / 100, tax: 255 },
    { min: 48001, max: 90000, excessRate: 0, tax: 1080 },
    { min: 90001, max: 126000, excessRate: -3 / 100, tax: 1080 },
    { min: 126001, excessRate: 0, tax: 0 },
  ];
  /** 2020 / 2021 Low Income Tax Offset Scales and Thresholds */
  let lowIncomeTaxComputation2 = 0;
  const lowIncomeTaxRef2 = [
    { min: 0, max: 37500, excessRate: 0, tax: 700, excessRate: -1 },
    { min: 37501, max: 45000, excessRate: -5 / 100, tax: 700 },
    { min: 45001, max: 66667, excessRate: -1.5 / 100, tax: 325 },
    { min: 66668, excessRate: 0, tax: 0 },
  ];

  /** 2020 / 2021 Medicare Levy Scales and Thresholds */
  let medicalLevyComputation = 0;
  // Info from reference data
  const medicalLevyRef = [
    { min: 0, max: 23226, excessRate: 0, tax: 0 },
    { min: 23227, max: 29033, excessRate: 10 / 100, tax: 0 },
    { min: 29034, excessRate: 2 / 100, tax: 581 },
  ];

  /** INCOME TAX */

  if (totalTaxableIncome > incomeTaxRef[4].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[4].min) * incomeTaxRef[4].excessRate +
      incomeTaxRef[4].tax;
  } else if (totalTaxableIncome > incomeTaxRef[3].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[3].min) * incomeTaxRef[3].excessRate +
      incomeTaxRef[3].tax;
  } else if (totalTaxableIncome > incomeTaxRef[2].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[2].min) * incomeTaxRef[2].excessRate +
      incomeTaxRef[2].tax;
  } else if (totalTaxableIncome > incomeTaxRef[1].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[1].min) * incomeTaxRef[1].excessRate +
      incomeTaxRef[1].tax;
  } else if (totalTaxableIncome > incomeTaxRef[0].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  } else {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax;
  }

  /** LOW INCOME */
  if (totalTaxableIncome > lowIncomeTaxRef[4].max) {
    lowIncomeTaxComputation = 0;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[3].min &&
    totalTaxableIncome < lowIncomeTaxRef[3].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef[3].min) *
        lowIncomeTaxRef[3].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[2].min &&
    totalTaxableIncome < lowIncomeTaxRef[2].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef[2].min) *
        lowIncomeTaxRef[2].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[1].min &&
    totalTaxableIncome < lowIncomeTaxRef[1].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef[1].min) *
        lowIncomeTaxRef[1].excessRate;
  } else if (
    totalTaxableIncome < lowIncomeTaxRef[0].max &&
    totalTaxableIncome > 0
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[0].tax +
      (totalTaxableIncome - lowIncomeTaxRef[0].max) *
        lowIncomeTaxRef[0].excessRate;
  }

  /** LOW INCOME 2 */
  if (totalTaxableIncome > lowIncomeTaxRef2[3].max) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].max) *
        lowIncomeTaxRef2[3].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[2].min &&
    totalTaxableIncome < lowIncomeTaxRef2[2].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].min) *
        lowIncomeTaxRef2[2].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[1].min &&
    totalTaxableIncome < lowIncomeTaxRef2[1].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[1].min) *
        lowIncomeTaxRef2[1].excessRate;
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[0].min &&
    totalTaxableIncome < lowIncomeTaxRef2[0].max
  ) {
    lowIncomeTaxComputation2 = lowIncomeTaxRef2[0].tax;
  }

  const allLowIncomeTax = -lowIncomeTaxComputation - lowIncomeTaxComputation2;

  /** MEDICAL LEVY */
  if (totalTaxableIncome > medicalLevyRef[1].max) {
    medicalLevyComputation =
      medicalLevyRef[2].tax +
      (totalTaxableIncome - medicalLevyRef[1].max) *
        medicalLevyRef[2].excessRate;
  } else if (
    totalTaxableIncome > medicalLevyRef[0].max &&
    totalTaxableIncome < medicalLevyRef[1].max
  ) {
    medicalLevyComputation =
      medicalLevyRef[0].tax +
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[1].excessRate;
  } else {
    medicalLevyComputation =
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax;
  }
  let estimatedTaxPaid =
    incomeTaxComputation + allLowIncomeTax + medicalLevyComputation;
  if (estimatedTaxPaid < 0) estimatedTaxPaid = 0;
  estimatedTaxPaid = Math.round(estimatedTaxPaid);
  return estimatedTaxPaid;
};
