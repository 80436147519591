angular
  .module("meanApp")
  .controller("moneysmartsChanges-controller", function (
    $scope,
    $stateParams,
    Auth,
  ) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    $scope.$on("moneysmarts-changes", function () {
      ReactDOM.render(
        React.createElement(MoneyStretchChanges.default, {
          id: $stateParams.clientId,
          isAdmin: $scope.isAdmin,
        }),
        document.getElementById("react-moneysmarts-changes")
      );
    });

    $scope.$on("$stateChangeStart", () => {
      ReactDOM.unmountComponentAtNode(
        document.getElementById("react-moneysmarts-changes")
      );
    });
  });
