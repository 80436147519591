angular
  .module("meanApp")
  .controller("property-portfolio-controller", function(
    $scope,
    $stateParams,
    Auth,
    $rootScope,
  ) {
    "use strict";

    const reactContainerNode = document.getElementById("react-wrapper");
    // check if user is admin
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    // get user ID from parent scope which is in the dashboard
    $scope.userID = $rootScope.loggedUserId || $stateParams.clientId;

    ReactDOM.render(
      React.createElement(PropertyPortfolio.default, {
        clientUID: $scope.isAdmin ? $stateParams.clientId : $scope.userID
      }),
      reactContainerNode
    );

    $scope.$on("$stateChangeStart", () => {
      ReactDOM.unmountComponentAtNode(reactContainerNode);
    });
  });
