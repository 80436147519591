angular.module('meanApp')

.controller('reset-controller', function($scope, Auth, User, $state, Idle) {
  'use strict';
  $scope.isAdmin = false;
  Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
  
  $scope.user = {};
  $scope.errors = {};
  $scope.isPasswordReset = false;
  $scope.isTCaccepted = false;
  $scope.isUpdateTCaccepted = false;
  $scope.showSpinner = false;
  $scope.showInactiveDialog = false;

  $scope.logout = function() {
    Auth.logout();
    $state.go('login');
  };

  $scope.init = function () {
    User.get(function(usr) {
      $scope.isPasswordReset = usr.passwordReset;
      $scope.isTCaccepted = usr.TCaccepted;
      $scope.isUpdateTCaccepted = usr.updateTCaccepted;
     
      //console.log("----",$scope.isTCaccepted);
    });
  }

  $scope.changePassword = function() {
    $scope.submitted = true;
    
    if($scope.user.password && $scope.user.newPassword) {
      $scope.showSpinner = true;
      $scope.errors = {};
      Auth.changePassword($scope.user.password,$scope.user.newPassword)
      .then(function() {
        // Logged in, redirect to dashboard
        $scope.showSpinner = false;
        Auth.isAdmin().then(isAdmin => {
          if(isAdmin){
            $state.go('dashboard-search');
          } else { 
            if($scope.isTCaccepted === true && $scope.isUpdateTCaccepted === true){
              $state.go('dashboard');
            }else{
              $state.go('terms');
            }
          }
        });
      })
      .catch( function(err) {
        $scope.showSpinner = false;
        if(err.status === 403){
         $scope.errors.msg = 'The current password is incorrect';
        }
        else {
          //console.log(err);
          $scope.errors.msg = 'Error resetting your password';
        }
      });
    } else {
      $scope.errors.msg = 'Please fill out password form';
    }

  };

})