angular
  .module("meanApp")
  .controller(
    "documentCollection-controller",
    function ($scope, DataM, $stateParams, Auth, $q, $state) {
      "use strict";

      $scope.toSave = false;
      $scope.toDiscard = false;
      $scope.showChangesAlert = false;
      $scope.haveChanges = false;
      $scope.isAdmin = false;
      $scope.toStateName = "";
      $scope.stateToChange = false;

      const fetchAuthIsAdmin = Auth.isAdmin();
      const $clientId = $stateParams.clientId;
      const clientData = $clientId
        ? DataM.getClientSummary({ id: $clientId })
        : DataM.getMSSumarry();

      $scope.closeChangesAlert = function () {
        $scope.showChangesAlert = false;
      };

      $scope.triggerToSave = () => {
        $scope.toSave = true;
        $scope.toDiscard = false;
        $scope.showChangesAlert = false;
        $scope.haveChanges = false;
        renderReactApp();
      };
      $scope.triggerToDiscard = () => {
        $scope.haveChanges = false;
        $state.go($scope.toStateName, {
          clientId: $clientId
        })
      };
      const reactContainerNode = document.getElementById("react-wrapper");


      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.$watch('haveChanges', () => {

        console.log({
          haveChanges : $scope.haveChanges
        })
      })
      const renderReactApp = () => {
        
        $q.all([fetchAuthIsAdmin, clientData]).then(([isAdmin, clientData]) => {
          ReactDOM.render(
            React.createElement(SettlementForm.DocumentCollection, {
              client_id: $clientId ? $clientId : clientData?.clientSummary?.uid,
              user_email: Auth.getCurrentUser().email,
              user_id: Auth.getCurrentUser()._id,
              client_email:
                clientData?.clientSummary?.personalInfo?.client1?.email,
              client_info:
                clientData?.clientSummary?.personalInfo,
              isAdmin: isAdmin,
              url: process.env.API_ENDPOINT,
              
              setContainerToHaveChanges: (value) => {
                $scope.haveChanges = value;
              },
              toStateProceed : () => {
                $scope.stateToChange = false;
                $scope.haveChanges = false;
                setTimeout(function() {
                  $state.go($scope.toStateName, {
                    clientId: $clientId
                  })
                }, 1000)
              },
              triggerSave: $scope.toSave,
              triggerDiscard: $scope.toDiscard,
              stateToChange: $scope.stateToChange,
            }),
            reactContainerNode
          );
        });
      };

      renderReactApp();

      $scope.$on("$stateChangeStart", (event, toState) => {
        console.log({
          haveChanges : $scope.haveChanges
        })
        if ($scope.haveChanges) {
          event.preventDefault();
          $scope.showChangesAlert = true;
          $scope.toStateName = toState.name
          $scope.stateToChange = true;
        }
        else {
          ReactDOM.unmountComponentAtNode(reactContainerNode);
        }
        console.log("statechange", { toState });
      });
    }
  );
