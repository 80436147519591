import handleTabName from "./handleTabName";

angular
  .module("meanApp")
  .controller("mbWorkbench-controller", function($scope, DataM, $stateParams,Auth, $rootScope, $state, DataHelper) {
    "use strict";
    const reactContainerNode = document.getElementById("react-wrapper");
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.clientId = $stateParams.clientId;

    const summaryPromise = $stateParams.clientId
    ? DataM.clientMSSummary({ id: $stateParams.clientId })
    : DataM.getMSSumarry();
    summaryPromise.then(async (data) => {

    
        const returnDefault = (item, type) => {
            let baseDefault;
            switch(type) {
                case "string":
                    baseDefault = ""
                break;
                case "number":
                    baseDefault = 0
                break
                default:
                    baseDefault = null
            }

            if (item === null || item === undefined) {
               return baseDefault 
            } else {
                return item
            }

        }

        const checkEmptyPeriod = (period) => {
            return _.isNull(period) || period === "" ? "Weekly" : period
        }

        const client1CurrentEmployment = _.get(data.clientSummary, "income.client1PersonalIncome.currentEmployment", [])
        const client2CurrentEmployment = _.get(data.clientSummary, "income.client2PersonalIncome.currentEmployment", [])
        const client1CurrentArr = client1CurrentEmployment.filter(employment => {
            const typeOfEmployment = returnDefault(_.get(employment, "employmentType", ""), "string")
            return typeOfEmployment === "PAYG"
        }).map(employment => {
            const typeOfEmployment = returnDefault(_.get(employment, "employmentType", ""), "string")
            let jobObj = {
                type: typeOfEmployment,
                portal: returnDefault(_.get(employment, "job.annualSalary", 0), "number"),
                period: "Yearly",
                brokerInput: returnDefault(_.get(employment, "job.annualSalary", 0), "number") ,
                summaryId: employment._id
            }
            return jobObj
        })

        const client2CurrentArr = client2CurrentEmployment.filter(employment => {
            const typeOfEmployment = returnDefault(_.get(employment, "employmentType", ""), "string")
            return typeOfEmployment === "PAYG"
        }).map(employment => {
            const typeOfEmployment = returnDefault(_.get(employment, "employmentType", ""), "string")
            let jobObj = {
                type: typeOfEmployment,
                portal: returnDefault(_.get(employment, "job.annualSalary", 0), "number"),
                period: "Yearly",
                brokerInput: returnDefault(_.get(employment, "job.annualSalary", 0), "number") ,
                summaryId: employment._id
            }
            return jobObj
        })

        const client1OtherIncome = _.get(data.clientSummary, "income.client1PersonalIncome.other", []).map(oIncome => {
            let reStructure = {
                type: "Other Income",
                portal: oIncome.amount || 0,
                period: "Yearly",
                brokerInput: oIncome.amount,
                summaryId: oIncome._id
            }
            return reStructure
        })
        const client2OtherIncome = _.get(data.clientSummary, "income.client2PersonalIncome.other", []).map(
            oIncome => {
                let reStructure = {
                    type: "Other Income",
                    portal: oIncome.amount || 0,
                    period: "Yearly",
                    brokerInput: oIncome.amount,
                    summaryId: oIncome._id
                }
                
                return reStructure
            }
        )
        
        let incomeBreakdownClient1 = [].concat.apply([], [client1CurrentArr, client1OtherIncome])
        let incomeBreakdownClient2 = [].concat.apply([], [client2CurrentArr, client2OtherIncome])

        const client1Personal = _.get(data.clientSummary, "personalInfo.client1", {fName: "", mName: "", lName: "", _id: null});
        const client2Personal = _.get(data.clientSummary, "personalInfo.client2", {fName: "", mName: "", lName: "", _id: null});
        const {fName, lName, _id} = client1Personal;
        const client1FullName = `${fName} ${lName}`
        const client2FullName = `${client2Personal.fName} ${client2Personal.lName}`
        let ownersName = {
            client1: {
                fullName: client1FullName,
                id: _id,
                selected: false
            },
            client2: {
                fullName: client2FullName,
                id: client2Personal._id,
                selected: false,
            }
        }

        const investmentProperties = _.get(data.clientSummary, "assets.properties", [])
        const borrowings = _.get(data.clientSummary, "borrowings.borrowing", [])
        let rentalIncome = investmentProperties.filter(property => {
            return property.purpose === "Investment"
        }).map(property => {
            
            const street = returnDefault(_.get(property, "address.street", ""), "string")
            const lotnumber = returnDefault(_.get(property, "address.number", ""), "string")
            const unitAddress = returnDefault(_.get(property, "address.unit", ""), "string")
            const address = `${unitAddress} ${lotnumber} ${street}`
            const grossRentalIncome = returnDefault(_.get(property, "grossRentalIncome", 0), "number")
            const period = "Yearly"
            const client1Ownership = returnDefault(property.ownership.owners.find(o => o.owner === client1Personal._id)?.percentage, "number")
            const client2Ownership = returnDefault(property.ownership.owners.find(o => o.owner === client2Personal._id)?.percentage, "number")
            const currentValue = returnDefault(_.get(property, "currentValue", 0), "number")
            const summaryId = _.get(property, "_id", "")
            return {
                address,
                rent: grossRentalIncome,
                period,
                ownershipPercentageClient1: client1Ownership,
                ownershipPercentageClient2: client2Ownership,
                propertyValue: currentValue,
                isCustom: false,
                summaryId
            }
        })
        
        const homeLoans = borrowings.filter(borrow => {
            let borrowingType = borrow.type
            if (borrowingType === null || borrowingType === undefined) {
                borrowingType = "Other"
            }
            return (borrowingType.indexOf("Home Loan") !== -1 || borrowingType.indexOf("Investment Loan") !== -1) && borrowingType !== null && !borrow.isClosed
        }).map(borrowing => {
            let addresses = []
            if(!_.isNil(borrowing.properties)) {
                addresses = borrowing.securedAgainst.map(ids => {
                    const property = investmentProperties.find(prop => prop._id === ids)
                    const street = _.get(property, "address.street", "")
                    const lotnumber = _.get(property, "address.number")
                    const propertyAddress = `${lotnumber} ${street}`
                    return propertyAddress
                })
            }
            const lender = returnDefault(_.get(borrowing, "provider", ""), "string")
            const loanType = returnDefault(_.get(borrowing, "type", ""), "string")
            const primaryPurpose = returnDefault(_.get(borrowing, "primaryPurpose", ""), "string")
            const borrower = returnDefault(_.get(borrowing, "borrower.ownershipType", ""), "string")
            const repaymentType = returnDefault(_.get(borrowing, "repaymentType", ""), "string")
            const loanExpiry = returnDefault(_.get(borrowing, "expiryDate", ""), "string")
            const balance = returnDefault(_.get(borrowing, "outstanding", 0), "number")
            const repayment = returnDefault(_.get(borrowing, "repayment", 0), "number")
            let repaymentFreq = returnDefault(_.get(borrowing, "repaymentFreq", ""), "string")
            const accountNumber = returnDefault(_.get(borrowing, "accountNumber", ""), "string")
            const ioExp = returnDefault(_.get(borrowing, "interestOnlyEndDate", ""), "string")
            const fixedIO = returnDefault(_.get(borrowing, "fixedRateEndDate", ""), "string")
            const summaryId = returnDefault(_.get(borrowing, "_id", ""), "string")
            const limit = returnDefault(_.get(borrowing,"limit", 0), "number")
            const baseRate = returnDefault(_.get(borrowing, "baseRate", 0), "number")
            const interestRate = returnDefault(_.get(borrowing, "interestRate", 0), "number")
            const discountRate = returnDefault(_.get(borrowing, "discountRate", 0), "number")

            return {
                address: addresses,
                lender,
                loanType,
                primaryPurpose,
                borrower,
                repaymentType,
                loanExpiry,
                ioExp,
                fixedIO,
                isRefi: false,
                balance,
                redraw: limit - balance,
                repayment,
                baseRate,
                interestRate,
                discountRate,
                period: checkEmptyPeriod(repaymentFreq),
                accountNumber,
                summaryId
            }
        })
    

        const unsecuredLiabilities = borrowings.filter(borrow => {
            //filter all borrowings except credit, home loans and investments
            let borrowingType = borrow.type === null || borrow.type === undefined ? "Other" : borrow.type
            return (borrowingType.indexOf("Personal Loan") !== -1 || borrowingType.indexOf("Car Loan") !== -1 ||
                borrowingType.indexOf("Commercial Bill") !== -1 || borrowingType.indexOf("Charge Card") !== -1 || borrowingType.indexOf("ATO") !== -1
                || borrowingType.indexOf("HECS") !== -1 || borrowingType.indexOf("Hire Purchase") !== -1 ||
                borrowingType.indexOf("Lease") !== -1 || borrowingType.indexOf("Overdraft") !== -1 || borrowingType.indexOf("Court Ruled") !== -1
                || borrowingType.indexOf("Store Card") !== -1 || borrowingType.indexOf("Outstanding Taxation") !== -1
                || borrowingType.indexOf("Maintenance") !== -1 || borrowingType.indexOf("Loan As Guarantor") !== -1 ||
                borrowingType.indexOf("Contingent Liability") !== -1 || borrowingType.indexOf("Other") !== -1)
                && borrowingType !== null && !borrow.isClosed
        }).map(borrowing => {
            let balance = returnDefault(_.get(borrowing, "outstanding", 0), "number")
            let borrowType = _.get(borrowing, "type", "")
            let repayment = returnDefault(_.get(borrowing, "repayment", 0), "number")
            let period = _.get(borrowing, "repaymentFreq", "")
            const borrower = _.get(borrowing, "borrower.owners", [])
            const allBorrowers = borrower.map(owner => owner.owner.toString())
            let owners = JSON.parse(JSON.stringify(ownersName))
            const summaryId = _.get(borrowing, "_id", "")
            const interestRate = returnDefault(_.get(borrowing, "interestRate", 0), "number")

            let selected1
            let selected2
            if (allBorrowers.length === 1) {
                selected1 = owners.client1.id !== null ? owners.client1.id.toString() === allBorrowers[0] : false
                selected2 = owners.client2.id !== null ? owners.client2.id.toString() === allBorrowers[0] : false
            } else if (allBorrowers.length === 2) {
                selected1 = owners.client1.id !== null ? owners.client1.id.toString() === allBorrowers[0] : false
                selected2 = owners.client2.id !== null ? owners.client2.id.toString() === allBorrowers[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2
            if (_.isNull(borrowType)) {
                borrowType = "Other"
            }
             
            const unsecured = {
                type: borrowType,
                balance,
                repayment,
                period: checkEmptyPeriod(period),
                rate: interestRate,
                owner: owners,
                summaryId
            }
            return unsecured
        })

        const creditCard = borrowings.filter(borrow => {
            let borrowingType = borrow.type === null || borrow.type === undefined? "Other" : borrow.type
            return borrowingType.indexOf("Credit Card") !== -1 && borrowingType !== null && !borrow.isClosed
        }).map(borrowing => {
            const summaryId = _.get(borrowing, "_id", "")
            let lender = returnDefault(_.get(borrowing, "provider", ""), "string")
            let limit = returnDefault(_.get(borrowing, "limit", 0), "number")
            let owner = _.get(borrowing, "borrower.owners", []).map(owner => owner.owner)
            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1 = false
            let selected2 = false
            if (owner.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === owner[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === owner[0] : false
            } else if (owner.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === owner[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === owner[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2
            const credit = {
                lender,
                limit,
                proposedLimit: limit,
                owner: owners,
                summaryId
            }
            return credit
        })
        const assets = _.get(data.clientSummary, "assets", {})
        const financialAssetsBank = _.get(assets, "bankAccounts", []).map(bank => {
            let summaryId = _.get(bank, "_id", "")

            let balance = returnDefault(_.get(bank, "balance", 0), "number")
            let ownersArr = _.get(bank, "ownership.owners", [])
            let allOwners = ownersArr.map(ownership => {
                return ownership.owner
            })
            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1
            let selected2
            if (allOwners.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[0] : false
            } else if (allOwners.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2
            const bankAsset = {
                type: "Bank Account",
                balance,
                owner: owners,
                summaryId,
                yearlyIncome: 0
            }
            return bankAsset
        })

        const financialAssetInvestment = _.get(assets, "investments", []).map(bank => {
            let summaryId = _.get(bank, "_id", "")

            let balance = returnDefault(_.get(bank, "currentValue", 0), "number")
            let ownersArr = _.get(bank, "ownership.owners", [])
            let allOwners = ownersArr.map(ownership => {
                return ownership.owner
            })

            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1
            let selected2
            if (allOwners.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[0] : false
            } else if (allOwners.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2

            const yearlyIncome = returnDefault(_.get(bank, "yearlyIncome", 0), "number")

            const investment = {
                type: "Investment",
                balance,
                owner: owners,
                summaryId,
                yearlyIncome,
                ownershipPercentageClient1 : returnDefault(_.get(bank, "ownership.owners[0].percentage", 0), "number"),
                ownershipPercentageClient2 : returnDefault(_.get(bank, "ownership.owners[1].percentage", 0), "number")
            }
            return investment
        })

        const financialAssetInsurance = _.get(assets, "lifeInsurance", []).map(bank => {
            let summaryId = _.get(bank, "_id", "")

            let balance = returnDefault(_.get(bank, "value", 0), "number")
            let ownersArr = _.get(bank, "ownership.owners", [])
            let allOwners = ownersArr.map(ownership => {
                return ownership.owner
            })

            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1
            let selected2
            if (allOwners.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[0] : false
            } else if (allOwners.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2
            const insurance = {
                type: "Life Insurance",
                balance,
                owner: owners,
                summaryId,
                yearlyIncome: 0
            }
            return insurance
        })

        const financialSuperFunds = _.get(assets, "superFunds", []).map(bank => {
            let summaryId = _.get(bank, "_id", "")

            let balance = returnDefault(_.get(bank, "value", 0), "number")
            let ownersArr = _.get(bank, "ownership.owners", [])
            let allOwners = ownersArr.map(ownership => {
                return ownership.owner
            })

            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1
            let selected2
            if (allOwners.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[0] : false
            } else if (allOwners.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2

            if (!balance) {
                balance = 0
            }
            const superFunds = {
                type: "Superannuation",
                balance,
                owner: owners,
                summaryId,
                yearlyIncome: 0
            }
            return superFunds
        })

        const nonFinancialVehicle = _.get(assets, "vehicles" , []).map(vehicle => {
            let summaryId = _.get(vehicle, "_id", "")

            let balance = returnDefault(_.get(vehicle, "value", 0), "number")
            let ownersArr = _.get(vehicle, "ownership.owners", [])
            let allOwners = ownersArr.map(ownership => {
                return ownership.owner
            })
            
            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1
            let selected2
            if (allOwners.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[0] : false
            } else if (allOwners.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2
            const vehicleAsset = {
                type: "Vehicle",
                balance,
                owner: owners,
                summaryId
            }
            return vehicleAsset
        })


        const nonFinancialOtherAssets = _.get(assets, "otherAssets", []).map(other => {
            let summaryId = _.get(other, "_id", "")

            let balance = returnDefault(_.get(other, "value", 0), "number")
            let ownersArr = _.get(other, "ownership.owners", [])
            let allOwners = ownersArr.map(ownership => {
                return ownership.owner
            })

            let owners = JSON.parse(JSON.stringify(ownersName))

            let selected1
            let selected2
            if (allOwners.length === 1) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[0] : false
            } else if (allOwners.length === 2) {
                selected1 = !_.isNil(owners.client1.id) ? owners.client1.id.toString() === allOwners[0] : false
                selected2 = !_.isNil(owners.client2.id) ? owners.client2.id.toString() === allOwners[1] : false
            }
            owners.client1.selected = selected1 === undefined ? false : selected1
            owners.client2.selected = selected2 === undefined ? false : selected2
            const otherAssets = {
                type: "Other Assets",
                balance,
                owner: owners,
                summaryId
            }
            return otherAssets
        })

        const allFinancialAssets = [].concat.apply([], [financialAssetsBank, financialAssetInvestment, financialAssetInsurance, financialSuperFunds])
        const allNonFinancialAssets = [].concat.apply([], [nonFinancialOtherAssets, nonFinancialVehicle])
        
        const flexExpenses = await DataM.getFlexSummary()
            .then(res => res.data)
            .then(res => {
                return res.map( r => ({
                    flexType : r.title,
                    items : r.sub.map(r2 => ({
                        desc : r2.title,
                        flexType : r.title,
                        frequency : "Yearly",
                        monthlyAmount : r2.yearlyAmount / 12,
                        amount: r2.yearlyAmount,
                        summaryId : r2.summaryId
                    })),
                    monthlyAmount : r.totalYearlyAmount / 12
                }))
            }
            );

        // Lending Forms
        $rootScope.globalFormId = ""
        $rootScope.setGlobalFormId = function(id) {
            $scope.globalFormId = id
        }
        // Lending Forms

        $rootScope.changeColumn = function (newColumn) {
            const prevTabName = $scope.activeColumn;

            $scope.activeColumn = newColumn;

            if (prevTabName === newColumn) return;

            const props = {
                userId: $stateParams.clientId,
                apiBaseUrl: process.env.API_ENDPOINT,
            };

            const isHandled = handleTabName(
                newColumn,
                reactContainerNode,
                prevTabName,
                props,
            );

            if (isHandled) return;

          if(newColumn !== "lending-form" && newColumn !== "lending-form-1") {
            $scope.globalFormId = ""
          }
          if (newColumn == "pre-assessment" || newColumn == "AssessmentCalculator") {
              
              
            const defaultProps = _.cloneDeep({
                uid: $stateParams.clientId,
                url: window.location.origin,
                owners: ownersName,
                incomeBreakdownClient1,
                incomeBreakdownClient2,
                rentalIncome,
                homeLoans,
                unsecuredLiabilities,
                creditCard,
                allFinancialAssets,
                allNonFinancialAssets,
                flexExpenses,
                navigatePage: function(page) {
                    $scope.changeColumn(page)
                }
            })

            ReactDOM.render(
              React.createElement(SettlementForm.PreliminaryAssessment, defaultProps),
              reactContainerNode
            );
  
          } else {
            ReactDOM.unmountComponentAtNode(reactContainerNode);
          }
        };

        $scope.pathname = window.location.pathname.split('/')
        $scope.tabUrl = $scope.pathname[$scope.pathname.length - 2]

        if($scope.tabUrl === 'mbworkbench') {
            $scope.activeColumn = 'proposed-lending'
            $scope.changeColumn('proposed-lending')
        } else { 
            $scope.changeColumn($scope.tabUrl)
        }
            
        $scope.goBack = function () {
            if($scope.activeColumn == "AssessmentCalculator") {
                ReactDOM.unmountComponentAtNode(reactContainerNode);
                $scope.changeColumn('pre-assessment')
            } else {
                window.history.back();
            }
        }
      });

        $scope.$on('$destroy', () => {
            ReactDOM.unmountComponentAtNode(reactContainerNode);
        })
    });
