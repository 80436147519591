angular
  .module("meanApp")
  .controller(
    "futurePlansChanges-controller",
    function ($scope, $stateParams, Auth, $q) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));
      $scope.$on("futurePlansChanges", function (evt, reqData) {
        ReactDOM.render(
          React.createElement(ModalUnansweredFields.FuturePlansChanges, {
            category: $stateParams.category, clientId: $stateParams.clientId, futurePlansProps: reqData
          }),
          document.getElementById("react-wrapper-futurePlansChanges")
        );
      });

      $scope.$on("$stateChangeStart", () => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("react-wrapper-futurePlansChanges")
        );
      });
    }
  );
