angular
  .module("meanApp")
  .controller("knowlegeCenter-controller", function (
    $scope,
    $stateParams,
    Auth
  ) {
    "use strict";
    $scope.isAdmin = false;
    Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

    $scope.$on("knowledgeCenter-react", function (evt, reqData) {
      ReactDOM.render(
        React.createElement(EmpowerWealthBlog.default, {
          id: $stateParams.clientId,
          isAdmin: $scope.isAdmin,
        }),
        document.getElementById("react-wrapper-knowledgeCenter")
      );
    });

    $scope.$on("$stateChangeStart", () => {
      ReactDOM.unmountComponentAtNode(
        document.getElementById("react-wrapper-knowledgeCenter")
      );
    });
  });
